/* styles.css */

.table-container {
    display: flex;
    justify-content: center;
    align-items: center; /* Optional: Align items vertically in center */
    height: 80vh; /* Optional: Ensure full viewport height */
  }
  
  .custom-table {
    color: white;
    width: 100%; /* Adjust width as needed */
    margin-top: 20px; /* Example margin */
  }
  
  .custom-table th,
  .custom-table td {
    padding: 11px;
    text-align: center;
    font-size: 12px;
  }
  

  
.transactions-container {
  max-width: 600px;
  padding: 100px 150px 150px 150px;
  border: 1px solid #cccccc81;
  border-radius: 30px;
  margin-top: 100px;
  background-color: #0000006c;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center */
  align-items: center; /* Horizontally center */
  height: 50%; /* Ensure the container takes the full height of the viewport */
  text-align: center;
}

@media only screen and (max-width: 600px) {
  .tracking-container {
      margin: 30px 0px 60px 20px; /* Add margin for mobile devices */
      height: 60%;
  }
}
