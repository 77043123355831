

.texthome2 {
  font-size: 16px;
  font-weight: 200;
  color: white;
  text-align: center;
  font-weight: 400;
}

.staking-container {

  background: linear-gradient(135deg, #000000, #073b62, #bd41d6);
    
  border-radius: 40px; /* Increased border radius for more rounded corners */

  padding: 20px 30px 20px 30px;
  border:0px;
  width: 100%;
  max-width: 500px;
  text-align: center;
  transition: transform 0.3s;
  margin-top: 10px; /* Add margin to separate from subtitle */
  max-width: 600px;

  padding: 20px; /* Reduced padding */
  margin: 50px auto; /* Center horizontally */
  
  border-radius: 30px;
  background-color: #0000006c;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center */
  align-items: center; /* Horizontally center */
  text-align: center;
}


.apy-title {
  margin-top: 10; /* Remove top margin to decrease distance */
  font-size: 24px;
}
.info-container {
  max-width: 600px; /* Match the staking container */
  padding: 20px; /* Match the staking container padding */
  margin: 20px auto; /* Center horizontally */
  margin-top: -30px;
  border: 1px solid #cccccc81; /* Match the staking container border */
  border-radius: 30px; /* Match the staking container border radius */
  background-color: #000000; /* Match the staking container background color */
  color: white; /* Changed to white for better visibility */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Vertically center */
  align-items: center; /* Horizontally center */
  text-align: center; /* Center text */
}

.info-container:hover {
  transform: translateY(-5px); /* Lift effect on hover */
}
.stats-row {
  display: flex; /* Use flexbox for horizontal layout */
  justify-content: space-between; /* Space between items */
  width: 100%; /* Full width */
}
.stat-item {
  flex: 1; /* Equal space for each item */
  text-align: center; /* Center text within each item */
}


@media only screen and (max-width: 600px) {
  .staking-container {
    margin: 10px 10px 10px 10px; /* Add margin for mobile devices */
    border: 0px solid #ffffff00;
    border-radius: 30px;
    background-color: #00000000;
  }

  .connected-wallet {
    font-size: 10px;
  }

 

 
  .texthome2 {
    text-align: center;
    color: white;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .center2-container {
    margin-top: 10px;
  }

  .button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .button-container button {
    flex: 1 1 45%;
    margin: 5px;
  }

  .info-container {
    margin-top: 30px; /* Add margin-top for mobile version */
  } 
}


.stake-button,
.unstake-button {
  margin-top:10px;
  font-size: 20px; /* Adjust font size if needed */
  border-radius: 6px; /* Keep existing border radius */
  background-color: #6c42a4; /* Example background color */
  color: white; /* Text color */
  cursor: pointer; /* Pointer cursor on hover */
  transition: background-color 0.2s ease; /* Transition effect */
}

.stake-button:hover,
.unstake-button:hover {
  background-color: #ffffff; /* Change background color on hover */
  color: #000000; /* Change text color on hover */
}