/* Modal overlay background */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Centered black box with rounded borders and white border */
.modal-content {
    position: relative;
    background-color: #000;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    width: 90%;
    max-width: 500px;
    max-height: 80%;
    overflow-y: auto;
    color: #fff;
    border: 1px solid #fff;
}

/* Title styling */


.connect-button{

    background-color: #1EC7DE;
}

.connect-button:hover{

    background-color: #EF10FFE1;
    color:white;
}
/* Common button styles */
.wallet-button, .cancel-button, .disconnect-button {
    background-color: #ffffff;
    color: #000000;
    padding: 8px 16px;
    margin: 5px;
    font-size: 16px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    width: 100%;
    max-width: 300px;
}
h2{margin-top:20px;}
/* Hover effect for buttons */

.wallet-button:hover, .cancel-button:hover, .disconnect-button:hover {
    background-color: #1EC7DE;
    color: rgb(255, 255, 255);
}

/* Error message styling */
.error-message {
    color: red;
    margin-top: 10px;
    font-size: 14px;
}

/* Wallet info section */
.wallet-info {
    margin-top: 20px;
    color: #fff;
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #fff;
}

.close-button:hover {
    color: #ccc;
}

/* New class for chain buttons container */
.chain-buttons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 10px;
}

/* Adjust button styles to fit two per row */
.wallet-button {
    flex: 0 0 45%;
    margin: 5px;
}

.chain-icon {
    width: 24px; /* Set the width of the icon */
    height: 24px; /* Set the height of the icon */
    margin-right: 8px; /* Space between the icon and text */
    vertical-align: middle; /* Align icon vertically with text */
    display: inline-block; /* Ensure the icon is treated as an inline element */
}
