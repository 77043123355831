/* Existing CSS styles */
.token-info-container {
  text-align: center;
  margin-top: 20px;
  color: #ffffff;
}

.token-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.contract-address-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.contract-address {
  font-size: 14px;
  color: #aaaaaa;
  margin-right: 10px;
}

.copy-button {
  background: none;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  color: #aaaaaa;
  cursor: pointer;
  padding: 2px 6px;
  font-size: 12px;
}

.social-links {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
  color: #ffffff;
}

.search-bar-container {
  padding: 20px;
  text-align: center;
}

.search-input {
  width: 80%;
  padding: 10px;
  border-radius: 10px;
  color: #333;
  border: 1px solid #ccc;
  margin-right: 10px;
}

.input2 {
  width: 100%;
  padding: 8px; /* Reduced padding for a more compact look */
  margin: 10px 0; /* Keep vertical margin */
  border-radius: 6px; /* 6px border radius */
  background: rgba(255, 255, 255, 0.231); /* Slightly transparent background */
  color: #000000; /* Set placeholder text color to white */
  border: none; /* Remove border color */
  transition: background-color 0.3s;
}

.search-button {
  padding: 10px 20px;
  border-radius: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  cursor: pointer;
}

.main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin: 0 auto;
  max-width: 1200px;
  padding: 20px;
}

.dexscreener-container {
  flex: 0 0 70%;
  margin-right: 10px;
}

.dexscreener-iframe {
  width: 100%;
  height: 660px;
  border: 0;
  border-radius: 20px;
}

.toggle-button-container {
  text-align: left;
  margin-bottom: 10px;
}

.toggle-button {
  padding: 10px 20px;
  background-color: #333;
  color: #fff;
  border-radius: 10px;
  border: none;
  cursor: pointer;
}

.uniswap-container {
  flex: 0 0 30%;
  margin-left: 10px;
}

.uniswap-iframe {
  width: 100%;
  height: 660px;
  border: 0;
  border-radius: 10px;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .main-content {
    flex-direction: column;
  }

  .dexscreener-container,
  .uniswap-container {
    flex: none;
    margin: 0;
    margin-bottom: 20px;
  }

  .dexscreener-iframe,
  .uniswap-iframe {
    height: 400px; /* Reduce iframe height for mobile */
  }

  .contract-address-container {
    flex-direction: column;
    align-items: center;
  }

  .contract-address {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .copy-button {
    margin-top: 10px;
    font-size: 14px; /* Increase font size for better readability */
  }
}
